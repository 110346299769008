import { useEffect, useState } from "react";
import { retrieveAllProducts } from "../api/retrieveAllProducts";
import { shopProduct } from "../types/shopProduct";
import ShopProductCard from "../components/ShopProductCard";
import userEvent from "@testing-library/user-event";
import sideFlarePic from "../images/title-image.jpg";
import hat from "../images/hat.png";
import patch from "../images/patch.png";
import dogtag from "../images/dogtag.png";

const Shop: React.FC = () => {
  const [products, setProducts] = useState<shopProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<shopProduct[]>([]);
  const [currentFilters, setCurrentFilters] = useState<any>({
    sortBy: "Default",
  });

  const sideflareItems: shopProduct[] = [
    {
      product_id: 1, // ShopProductCard checks if product ID === 1
      title: "Sideflare Collab: Custom Leather Dog Name Tags",
      description: "Sideflare Collab: Custom Leather Dog Name Tags",
      price: 4000,
      visible: true,
      image: sideFlarePic,
      link: "https://www.sideflare.us/products/pup-profile-collab-leather-dog-tags",
    },
    {
      product_id: 2, // ShopProductCard checks if product ID === 2
      title: "Sideflare Collab: Pup Profile Leather Patch Hat",
      description: "Sideflare Collab: Pup Profile Leather Patch Hat",
      price: 3500,
      visible: true,
      image: hat,
      link: "https://www.sideflare.us/products/pup-profile-leather-patch-hat-in-loden",
    },
    {
      product_id: 3, // ShopProductCard checks if product ID === 3
      title: "Sideflare Collab: Pup Profile Leather Patch",
      description: "Sideflare Collab: Pup Profile Leather Patch",
      price: 2000,
      visible: true,
      image: patch,
      link: "https://www.sideflare.us/products/pup-profile-leather-patch-1",
    },
    {
      product_id: 4, // ShopProductCard checks if product ID === 4
      title: "Sideflare Collab: Mini Leather Dog Name Tag",
      description: "Sideflare Collab: Mini Leather Dog Name Tag",
      price: 3000,
      visible: true,
      image: dogtag,
      link: "https://www.sideflare.us/products/copy-pup-profile-collab-mini-leather-dog-name-tags",
    },
  ];
  useEffect(() => {
    const setProductData = async (data: any) => {
      let tempProducts: shopProduct[] = [];
      data.forEach((item: any) => {
        const product: shopProduct = {
          product_id: item.product_id,
          title: item.title,
          description: item.description,
          price: item.first_price,
          visible: item.visible,
          image: item.first_image_url,
        };
        tempProducts.push(product);
      });

      // Adding sideflare collab to the end of printify products
      tempProducts.push(...sideflareItems);

      setProducts(tempProducts);
      setFilteredProducts(tempProducts);
    };
    const fetchProducts = async () => {
      const response = await retrieveAllProducts();
      setProductData(response);
    };
    try {
      fetchProducts();
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    applyFilters();
  }, [currentFilters.sortBy]);

  const filters: any = {
    categories: ["Apparel", "Accessories", "Dog Apparel"],
    sortby: ["Default", "Price (Low to High)", "Price (High to Low)"],
  };

  const applyFilters = () => {
    let tempProducts: shopProduct[] = [...products];

    if (currentFilters.sortBy === "Price (Low to High)") {
      tempProducts.sort((a, b) => a.price - b.price);
    } else if (currentFilters.sortBy === "Price (High to Low)") {
      tempProducts.sort((a, b) => b.price - a.price);
    }

    setFilteredProducts(tempProducts);
  };
  return (
    <div className="mb-32 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Shop Pup Profile</h1>
          <h2 className="px-10 text-center md:px-0 md:text-left">
            Shopping for a cause? Sign us up. Every purchase from our shop helps
            us help pups.
          </h2>
        </div>
      </section>
      <div className="mx-auto flex max-w-[1500px] flex-row justify-center">
        {/*start of left filter selection, left this here incase we want to add later, remove justify center from line above
        <div id="left-spacer" className="max-w-50 flex-1"></div>
        <section id="filters" className="mt-24 flex flex-col">
          <div className="flex flex-row gap-3">
            <p className="text-xl font-semibold text-gray3">Filters</p>
            <button className="text-gray5 underline"> Clear filters</button>
          </div>
          <p className="text-lg font-semibold">Categories</p>
          <div>
            {filters.categories.map((item: any, index: any) => (
              <div key={index} className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <label htmlFor={`checkbox-${index}`}>{item}</label>
              </div>
            ))}
          </div>
        </section>
        */}
        <div className="flex flex-col">
          <div className="flex justify-center md:justify-end">
            <div className="flex flex-col items-end">
              <p className="mt-4 w-full text-center text-lg font-semibold md:text-start">
                Sort by
              </p>
              <select
                className="mt-1 w-48 select-none rounded border border-gray-300 pl-1 focus:outline-none"
                value={currentFilters.sortBy}
                onChange={(e) => {
                  setCurrentFilters({
                    ...currentFilters,
                    sortBy: e.target.value,
                  });
                }}
              >
                {filters.sortby.map((option: string, index: number) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-3">
            {filteredProducts.map((product: shopProduct) => (
              <ShopProductCard key={product.product_id} product={product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
