import React, { useEffect } from "react";
import { shopProduct } from "../types/shopProduct";
import { fixPrice } from "../utils/fixPrice";
import { useNavigate } from "react-router-dom";

interface ShopProductCardProps {
  product: shopProduct;
}

const ShopProductCard: React.FC<ShopProductCardProps> = ({ product }) => {
  const navigate = useNavigate();

  const handleOpenSideFlareUrl = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    link?: string,
  ) => {
    event.preventDefault();

    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <img
          src={product.image}
          alt="Profile"
          className="h-[265px] w-[271px]"
        />
        <h1 className="mt-2 text-center font-semibold text-gray3">
          {product.title}
        </h1>
        <h2 className="mt-1 text-center text-gray3">
          ${fixPrice(product.price)}
        </h2>
        <button
          onClick={(event) => {
            if (product.product_id < 5) {
              handleOpenSideFlareUrl(event, product.link);
            } else {
              navigate(`/shop/${product.product_id}`);
              window.scrollTo(0, 0);
            }
          }}
          className="mt-1 w-fit text-nowrap rounded-md bg-blue px-7 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50"
        >
          Shop Now
        </button>
      </div>
    </>
  );
};

export default ShopProductCard;
